div.DraftEditor-root {
  border: 1px solid lightgrey;
  border-radius: 4px;
  background-color: white;
  padding: 8px;
  min-height: 150px;
  resize: vertical;
  overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}